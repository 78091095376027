<template>
  <div>
    <template v-if="useAuthStore().isLoggedIn">
      <MainHeader />
      <div class="min-h-[100dvh] pt-14">
        <slot />
      </div>

      <Modal />
      <MainSettingsDialog />
      <UserPasswordChangeDialog />
      <UserInviteDialog />
      <PlanUpgradeDialog />
      <PlanOrderDialog />
      <WorkspaceAirtableDialog />
      <WorkspaceCustomDomainAddDialog />
      <WorkspaceCustomDomainSettingsDialog />
      <EventCreateDialog />
      <LoadingDialog />
      <PaymentResponseSuccessDialog />
    </template>

    <div
      v-else
      v-loading="true"
      class="w-screen h-[100dvh]"
    />
  </div>
</template>

<script setup>
// Refs
const i18n = useI18n();

// Computed
const route = useRoute();
const title = computed(() => {
  if (route?.meta?.title) {
    return `${typeof route.meta.title === 'function' ? route.meta.title() : route.meta.title} | Attendu`;
  }

  return 'Attendu';
});
useSeoMeta({
  title,
  ogTitle: title,
});

// Refs
const channel = useRealtime()?.channels?.get(`workspace-${useWorkspaceStore().domain}`);

// Lifecycle
if (useWorkspaceStore().integrations?.customDomain?.url && useRequestURL().host === useWorkspaceStore().integrations?.customDomain?.url) {
  navigateTo(`https://${useWorkspaceStore().domain}.${useRuntimeConfig().public.productionDomain}`, { external: true });
}

onMounted(async() => {
  channel?.subscribe('userRemove', ({ data }) => {
    console.log('[WS] userRemove', data);
    if (data?._id === useAuthStore().user?._id) {
      useAuthStore().logout();
    }
  });

  if (useAuthStore().user && !useAuthStore()?.user?.locale) { // save user locale if not set
    await useUsersStore().updateUserMe({
      locale: i18n?.locale?.value,
    });
    await useAuthStore().fetchUser();
  }
});

onUnmounted(() => {
  channel?.unsubscribe('userRemove');
});

// Watchers
watch(() => useAuthStore()?.user?.locale, (locale) => { // () => useRoute().path
  if (locale) {
    i18n.setLocale(locale);
    i18n.locale.value = locale;
  }
}, { immediate: true });

useHead({
  meta: [
    {
      name: 'robots',
      content: 'noindex',
    },
  ],
});
</script>
